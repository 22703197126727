import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Container, Typography, Box } from "@material-ui/core";
import Layout from '../components/Layout';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        padding: theme.spacing(2, 0)
      },
    }
  ),
);

const HowItWorks: React.FC = () => {
  const classes = useStyles();
  return (
    <Layout contentClassName={classes.root}>
      <Container maxWidth="md">
        <Typography variant="h4" align="center" paragraph>How it works</Typography>
        <Box mb={2}>
          <Typography variant="h6">
            Choose your perfect car
        </Typography>
          <Typography variant="body1">
          Pick the car you want with our easy to use car picker, provide some details about how much you are willing to put a down payment, for how long you want to lease a car and how many miles are you planning to drive.
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography variant="h6">
            Compare your offers
          </Typography>
          <Typography variant="body1">
          Once you‘ve chosen a car, we’ll send you offers from top-rated, approved dealers. Choose the offer that’s best for you, based on price, location and dealer ratings from other Leasemojo buyers. All our dealers are committed to giving you their best price upfront, which means no need to haggle and no hidden costs. We save our users an average of 20% in monthly payments.
          </Typography>
        </Box>


        <Box mb={2}>
          <Typography variant="h6">
            Best price guaranted
          </Typography>
          <Typography variant="body1">
          Our certified dealers and agents might suggest other options if applicable (if it will lower your monthly payments). For example, if you have requested the lease to be 36 months and there is a better deal for 24 months lease, they will offer you that option too, or you have requested a black color and there is a monthly special on a white color they will offer you that option too.
          </Typography>
        </Box>


        <Box mb={6}>
          <Typography variant="h6">
            It is safe
          </Typography>
          <Typography variant="body1">
          We do not run a credit check or require you to provide sensitive information, you lease the car directly from the dealer. We are simply helping you to find the best deal from the best dealers.
          </Typography>
        </Box>


      </Container>


    </Layout>
  );
};

export default HowItWorks;